.wave-container {  
    height: 22vh;
    background-color: #fdf9f3;
    position: relative;
  }
  
  .wave-container::before {   
    content: "";
    width: 100%;
    height: 136px;
    position: absolute;
    bottom: -0.3%;
    left: 0;
    background-size: auto;
    background-repeat: repeat no-repeat;
    background-position: 86vw bottom;
    background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 1200 128' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 1C19.5523 3 32.2291 5 51.5 8C104.5 18 200 41 300 63C400 85 500 106 600 98C700 89 800 51 900 29C987.526 5 1085.36 -1 1150 0C1169.54 -1 1180.49 0 1200 1V128H1150C1100 128 1000 128 900 128C800 128 700 128 600 128C500 128 400 128 300 128C200 128 100 128 50 128H0V1.98128Z' fill='%23C1B6AB'/></svg>");
  }
  
  @media(max-width:850px) {
    .wave-container::before {    
      height: 68px
    }  
    .wave-container{
        height: 14vh;
    }
  }

  .wave-container1 {  
    height: 10vh;
    background-color: #c1b6ab;
    position: relative;
  }
  
  .wave-container1::before {   
    content: "";
    width: 100%;
    height: 168px;
    position: absolute;
    bottom: -0.3%;
    left: 0;
    background-size: auto;
    background-repeat: repeat no-repeat;
    background-position: 28vw bottom;
    background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 1200  80' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 59L50 55C100 51 200 44 300 30C400 15 500 -6 600 1C700 8 800 44 900 59C1000 73 1100 66 1150 62L1200 59V80H1150C1100 80 1000 80 900 80C800 80 700 80 600 80C500 80 400 80 300 80C200 80 100 80 50 80H0V59Z' fill='%23FDF9F3'/></svg>");
  }
  
  @media(max-width:850px) {
    .wave-container1 {    
      height: 0px
    }
    .wave-container1::before {    
      height: 84px
    }  
  }

  .circle {
    border-radius: 50%;
    width: 58px;
    height: 58px;
    padding: 10px;
    background: "transparent";
    border: 3px solid #DA271B;
    color: #DA271B;
    text-align: center;
    font: 32px Arial, sans-serif;
  }

  .roomfac {
    display: flex;
    width: 100%;
    max-width: 60%;
    margin: 0 auto;
    justify-content: center;
}

input:focus {
  outline: none !important;
  border: 0px solid red !important;
  box-shadow: 0 0 3px red !important;
}

