#customToggleSimple {
  background-color: transparent !important;
  border: none !important;

}

.simpleToggle {
  background-color: white !important;
  border: none !important;
  font-size: medium !important;
  color: white !important;
  margin-left: 1rem !important;
  margin-top: 0.6rem !important;

}

.simpleToggle:hover {
  background-color: white !important;
  color: white !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: transparent !important;
}
