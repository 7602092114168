.btn-custom {
    background-color: #DA271B;
    color: #fff;
    border: #DA271B;
    border-radius: 10;
    padding: 15px 25px;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.3s ease;
}