.icon_bekreftet path{
    stroke: rgb(148, 205, 116);
    color:rgb(148, 205, 116)
}

.icon_ikkebekreftet path{
    stroke: rgb(100,149,237);
}

.icon_food path{
    stroke: rgb(218, 39, 27);
    color:rgb(218, 39, 27);
}

.icons_loading {
    stroke: rgb(218, 39, 27);
    color:rgb(218, 39, 27);
}